.fs-12 {
    font-size: 12px;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fs-32 {
    font-size: 32px !important;
}
.pr-2 {
    padding-right: 2rem;
}
.pl-2 {
    padding-left: 2rem;
}
.fs-20 {
    font-size: 20px;
}
.g-75 {
    gap: .75rem;
}
.g-5{
    gap: .5rem;
}
.mb-75 {
    margin-bottom: .75rem !important;
}