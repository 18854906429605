.providers-menu {
    background: #111112 !important;
    border: 1px solid #0c0c0d;
    box-shadow: 0px 16px 32px -20px rgba(0, 0, 0, 0.9);

    .mat-menu-content {
        color: #f7f8fa;
        padding: 0 !important;

        h1 {
            color: #f7f8fa;
        }

        .topic-content {
            border: 1px solid #535557 !important;
            color: #d7d8db;
        }

        .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
            background: #2E6CFF !important;
        }

        .mat-slide-toggle-bar {
            background: #535557 !important;
        }

        input[type="text"],
        [type="number"],
        [type="href"] {
            background: transparent;
            border: 1px solid #3b3b3d;
            color: #f7f8fa;
        }

        label,
        .menu-txt {
            color: #D7D8DB;
        }

        ::placeholder {
            color: #AAABAD;
        }

        .isActiveChip {
            background: #2E6CFF;
            color: #111112;
        }

        .bi-x-lg {
            opacity: .5;
            cursor: pointer;
        }
    }
}

.tag-menu {
    background: #111112;

    mat-panel-title {
        color: #ffffff;
    }

    .mat-expansion-panel-body {
        color: #f7f8fa;

        .text-gray {
            color: #aaabad;
        }

        .bg-light-gray {
            background: #0c0c0d;

            h4 {
                color: #f7f8fa;
            }
        }
    }

}

.select-menu {
    .mat-select-panel {
        border: 1px solid #0c0c0d;
        box-shadow: 0px 12px 24px -20px rgb(1 8 26 / 8%);
        border-radius: 12px;
        background: #111112;
    }

    .mat-option-text {
        color: #d7d8db;
    }
}

.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.cdk-overlay-container .cdk-overlay-pane {
    .testClass {
        ::-webkit-scrollbar-thumb {
            background: #3B3B3D !important;
        }

        border: 1px solid #0c0c0d;
        box-shadow: 0px 12px 24px -20px rgb(1 8 26 / 8%);
        border-radius: 12px;
        background: #111112;
        margin: 25px 0px !important;

        .mat-option-text {
            color: #d7d8db !important;
        }

        .mat-option.mat-active {
            background: #191d25 !important;
            border-radius: 8px !important;

            .mat-option-text {
                color: #f7f8fa !important;
            }
        }
        .mat-select-placeholder {
            color:#AAABAD !important;
        }

        .mat-pseudo-checkbox {
            border: 2px solid #AAABAD !important;
        }
        .mat-pseudo-checkbox-checked {
            border: 2px solid transparent !important;
        }

        input[type="text"],
        [type="number"],
        [type="href"] {
            background: transparent;
            border: 1px solid #3b3b3d;
            color: #f7f8fa;
        }
    }
}

.cdk-overlay-container .cdk-overlay-pane .selectMenu .mat-option.mat-active{
    background: #E5EDFF !important;
    color: #0E0F0F !important;
    margin: 6px 8px 6px 6px;
    border-radius: 8px;
}

.cdk-overlay-container .cdk-overlay-pane .selectMenu .mat-option.mat-active .mat-option-text{
    font-size: 16px;
    font-weight: 500;
    color:#0E0F0F !important
}
.cdk-overlay-container .cdk-overlay-pane .selectMenu .mat-option .mat-option-text{
    color: #404042;
    font-size: 16px;
    font-weight: 500;
}

.cdk-overlay-container .cdk-overlay-pane .selectMenu{
    margin-top: 30px;
}