$light-background: #bdbdbd;
$light-text: #036;
$dark-background: #111112;
$dark-text: #ffffff;

@mixin theme-colors($light-theme: true) {
    @if $light-theme {
        background-color: $light-background;
        color: $light-text;
    }

    @else {
        background-color: $dark-background;
        color: $dark-text;
        transition: all .3s linear;
    }
}

@mixin theme-link-colors($light-theme: true) {
    @if $light-theme {
        --bs-nav-pills-link-active-bg: #0c0c0d !important;
        color: #ffffff !important;
    }

    @else {
        --bs-nav-pills-link-active-bg: #ffffff !important;
        color: #0c0c0d !important;
    }
}

@mixin table-colors($light-theme: true) {
    @if $light-theme {
        color: #404042
    }

    @else {
        color: #D7D8DB;
    }
}

.banner {
    @include theme-colors($light-theme: true);

    .dark & {
        @include theme-colors($light-theme: false);

        .input {
            background-color: #0C0C0D !important;
            border: 1px solid #3B3B3D;
            color: white !important;
        }

        // .btn-outline-primary {
        //     color: #ffffff;
        //     border: 2px solid #ffffff;
        //     }

        .btn-outline-primary:hover {
            background: #191D25;
        }
        .btn-outline-light {
            border: 2px solid #191D25;
            color: #D7D8DB !important;
        }
        .btn-outline-light:hover {
            background: #191D25;
        }
    }
}

.nav-link.active {
    @include theme-link-colors($light-theme: true);

    .dark-nav & {
        @include theme-link-colors($light-theme: false);
    }
}

.table-body {
    @include table-colors($light-theme: true);

    .dark-table & {
        @include table-colors($light-theme: false);
    }
}