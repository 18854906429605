.dark-modal {
    .modal-content {
        ::-webkit-scrollbar-thumb {
            background: #3b3b3d !important;
        }
        
        input[type=text], [type=number], [type=href] {
            background: transparent;
            border: 1px solid #3b3b3d;
            color: #2e6cff;
        }
        background: #0c0c0d;
        border: 1px solid grey;
        box-shadow: 0px 16px 32px -20px rgb(0 0 0 / 90%);
        color: #ffffff;

        .light-purple {
            color: #EFE5FF;
        }
        .heading {
            color: #ffffff;;
        }
        .btn-orange {
            background: #26231b;
            color: #f7f8fa !important;
        }
        .btn-light-pink {
            background: #3e2133;
        }
        .outline-tags:hover {
            .drag-icon {
                color: silver;
                background: #5a5a52 !important;
            }
        }

        .light-content {
            color: #d7d8db;
        }
        .dull-white {
            color: #F7F8FA;
        }
        .btn-close {
        opacity: 1 !important;
            // background: url(../../icons/close.svg) no-repeat;
        }

        .neutral-dark {
            color: #aaabad;
        }

        .heading-border {
            border-bottom: 1px solid #535557 !important;
        }

        .bg-light-black {
            background: #111112;
        }

        input[type="text"],
        [type="number"],
        [type="href"] {
            background: transparent;
            border: 1px solid #3b3b3d;
            color: #f7f8fa;
        }

        select {
            border: 1px solid #3b3b3d;
            color: #f7f8fa;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: #111112 url(../../icons/dark-dropdown.svg) no-repeat;
            background-position: right 12px center;
        }

        .btn-outline-primary:hover {
            background: #191d25;
        }

        .btn-outline-primary {
            color: #043fcc;
            border: 2px solid #191d25;
        }

        label,
        .data-number {
            color: #d7d8db;
        }

        thead,
        .data-word,
        .data-link {
            color: #aaabad !important;
        }

        .align-middle {
            .data-heading {
                color: #f7f8fa !important;
            }

            .data-content {
                color: #d7d8db !important;
            }

            .disable-row {
                .chip {
                    background: #3b3b3d;
                    color: #ffffff;
                }
            }

            .chip {
                color: #111112;
            }
        }

        .checkbox-checked-color {
            .data-content {
                color: #f7f8fa !important;
            }
        }

        .hover tbody tr:hover td,
        .hover tbody tr:hover th,
        .checkbox-checked-color {
            background: #191d25 !important;
            color: #d7d8db !important;

            .index-box {
                background: #a2bbf9 !important;
            }
        }

        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
            border-color: rgba(125, 126, 128, 0.15);
        }

        .dark-table .form-check-input:checked[type="checkbox"] {
            background: url("../../icons/checked.svg") no-repeat;
            background-color: #2e6cff;
            border-color: #2e6cff !important;
            background-position: center;
        }

        .dark-table .form-check-input[type="checkbox"] {
            border: 2px solid #aaabad !important;
        }

        .dark-table tbody tr:hover td,
        .dark-table tbody tr:hover th {
            background: #191d25 !important;
            color: #f7f8fa !important;
        }

        .index-box {
            background: #3b3b3d;
            color: #f7f8fa;
        }

        .icon-btn {
            background: #0c0c0d;
        }

        .ContentStructure {
            .mat-button-toggle-group {
                background: #0c0c0d;
                border: 1px solid rgba(125, 126, 128, 0.15);
            }

            .mat-button-toggle {
                background: #0c0c0d;
                color: #aaabad;
            }

            .mat-button-toggle-checked {
                color: #111112 !important;
                background: #d7d8db !important;
            }
        }

        .term-setting {
            .keyWord-border {
                border: 2px solid #efe5ff;
            }

            .border-gray {
                border: 2px solid #35156b;
            }

            .bg-gray {
                background: #35156b;
            }

            .bg-exculde {
                background: #111112;
            }

            .border-exclude {
                border: 2px solid #111112;
            }

            .person-dash-box {
                background: #535557;

                .bi-person-dash-fill {
                    color: #111112;
                }
            }
        }

        .dark-input .mat-form-field-outline {
            background: #111112 !important;
            color: #f7f8fa;
            border-radius: 12px;
        }
        input[type=text], .dark .banner [type=number], .dark .banner [type=href] , textarea {
            background: transparent;
            border: 1px solid #3b3b3d;
            color: #2e6cff;
        }

        .dark-input .mat-select-value {
            color: #f7f8fa !important;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline {
            color: #3b3b3d !important;
        }

        .NgxEditor {
            background: #111112 !important;
        }
    }
}


