.dark-table tbody tr:hover td,
.dark-table tbody tr:hover th {
    background: #191d25 !important;
    color: #f7f8fa !important;

    .copy-icon {
        display: inline-block !important;
        background: #111112 !important;
        color: #ffff;
    }
}

.dark-table {
    .form-check-input[type="checkbox"] {
        border: 2px solid #aaabad;
    }

    .bi-bookmark {
        color: #aaabad;
        -webkit-text-stroke: 1px;
    }

    .bg-dark {
        background: #191d25 !important;
        color: #f7f8fa !important;
    }

    .bg-light-blue {
        background: #191d25 !important;
    }

    .no-image {
        background: #0c0c0d !important;
        border: 0.5px solid #3b3b3d !important;
        border-radius: 6px !important;
    }

    .image {
        border: 0.5px solid #3b3b3d !important;
    }
}

.dark-nav {
    .nav-link {
        color: white;

        span {
            color: #aaabad;
        }
    }

    .nav-link.active {
        span {
            color: #0c0c0d;
        }
    }
}

.dark-nav-link {
    a.nav-link.dark-link.active {
        color: #f7f8fa !important;
        border-bottom: 2px solid #f7f8fa;
    }

    a.nav-link.dark-link {
        color: #aaabad;
    }
}

.dark {
    .banner {
        .total-page {
            color: #d7d8db;
        }
        .dark-right-border {
            border-right: 1px solid #3B3B3D;
        }
        .mat-expansion-panel {
            border-radius: 0;
            box-shadow: none !important;
            background: #111112;
        }
        .mat-expansion-panel-header {
            // background: #111112 !important;

            .mat-icon,
            h3,
            h4,
            p,
            .mat-expansion-indicator::after {
                color: #ffff;
            }
        }

        .mat-expansion-panel-content {
            background: #111112 !important;

            h4 {
                color: #ffff;
            }
        }

        .page-link.active,
        .active > .page-link {
            background: #2e6cff;
            border: 1px solid #2e6cff;
        }

        .page-link {
            background: #0c0c0d;
            color: #f7f8fa;
        }

        .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
            background: #2e6cff !important;
        }

        .mat-slide-toggle-bar {
            background: #535557 !important;
        }

        .dark-card {
            background: #111112;
            border: 1px solid #111112;
            border-radius: 12px;

            .bi-arrow-right-short {
                display: none;
            }
        }

        .dark-card:hover {
            border: 1px solid #2e6cff !important;

            .bi-arrow-right-short {
                display: inline-block;
            }
        }

        .credits-dark-card {
            background: #111112;
            border-radius: 12px;

            .heading {
                color: #fff;
            }
        }

        .create-content-dark-card {
            background: #111112;
            border: 1px solid rgba(162, 187, 249, 0.2);
            box-shadow: 0px 32px 64px -20px #000000;
        }

        tspan {
            fill: #d7d8db !important;
        }

        .email-chip {
            background: #191d25;
            border: 1px solid #a2bbf9;

            span {
                color: #aaabad;
            }
        }
        .dark-border {
            border: 1px solid #3b3b3d !important;
            .bg-light-yellow {
                background: #fff5e0;
            }
            // .bg-light-green {
            //     background: #3b3b3d;
            // }
        }
        .dark-content {
            color: #d7d8db;
        }
        .heading {
            color: #fff;
        }
        .light-neutral {
            color: #f7f8fa !important;
        }
        .bg-dark-neutral {
            background: #0c0c0d;
        }
        .dull-neutral {
            color: #aaabad;
        }

        .round-border {
            border: 1px solid #535557;
        }
        .dark-gray {
            color: #535557;
        }
    

        .light-txt {
            color: #ffffff !important;
        }
        .social-panel {
            .bg-light-gray {
                background: #111112 !important;
            }
        }
        .dark-load {
            .border {
                background: #111112;
                border: 2px solid #3b3b3d !important;
            }
        }

        .dark-input .mat-form-field-outline {
            background: #111112 !important;
            color: #f7f8fa;
            border-radius: 12px;
        }

        .dark-input .mat-select-value {
            color: #f7f8fa !important;
        }

        select option:checked {
            background-color: #191d25 !important;
            border-radius: 8px !important;
            margin: 6px 8px 6px 6px;
            color: #d7d8db;
        }

        .mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: rgb(79, 3, 80) !important;
        }

        .mat-select-value {
            color: #f7f8fa !important;
        }

        .mat-select-panel {
            width: 100%;
            box-shadow: none;
            background-color: rgb(79, 3, 80);
        }

        .mat-form-field-should-float .dark-input .mat-focused {
            background-color: red !important;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline {
            color: #3b3b3d !important;
        }

        .mat-select-placeholder {
            color: #f7f8fa;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline {
            color: #f7f8fa;
        }

        .dark-collapse {
            .collapse-btn {
                button {
                    background: #111112;

                    mat-icon {
                        color: #ffffff;
                    }
                }
            }
        }

        .social-btn {
            .bg-light-gray {
                background: #0c0c0d !important;
            }

            .bg-light-gray:hover {
                background: #191d25 !important;
            }

            .bi {
                color: #d7d8db !important;
            }
        }

        .dark-beginner {
            .bi {
                color: #535557;
            }
        }

        .txt-editor-btns {
            button {
                color: #f7f8fa;

                span {
                    color: #d7d8db;
                }
            }

            .active-btn {
                span {
                    color: #3b3b3d !important;
                }
            }
        }

        .dark-keyword-btn {
            .btn-sky-blue {
                border: 1px solid #386651;

                .bg-green {
                    background: #386651;
                    color: #cefcdb;
                }

                .bi-three-dots-vertical {
                    color: #386651;
                }
            }

            .btn-orange {
                border: 1px solid #822d12;
                // .bg-orange {
                //     background: #822d12;
                //     color: #ffeac3;
                // }

                .bi-three-dots-vertical {
                    color: #822d12;
                }
            }

            .btn-light-pink {
                border: 1px solid #6e0505;
            }
        }
    }
}

.dark-editor {
    .NgxEditor__MenuBar {
        background: #0c0c0d !important;
        border-bottom: 1px solid #0c0c0d;
    }

    .NgxEditor__MenuItem:hover,
    .NgxEditor__Dropdown:hover,
    .NgxEditor__Dropdown .NgxEditor__Dropdown--Open,
    .NgxEditor__Dropdown .NgxEditor__Dropdown--DropdownMenu,
    .NgxEditor__Dropdown .NgxEditor__Dropdown--Item:hover,
    .NgxEditor__Dropdown .NgxEditor__Dropdown--Active {
        background-color: #191d25 !important;
        color: #fff;
    }

    .NgxEditor__Dropdown .NgxEditor__Dropdown--Selected,
    .NgxEditor__MenuItem.NgxEditor__MenuItem--Active,
    .NgxEditor__MenuItem .NgxEditor__MenuItem--Active {
        background: #0c0c0d !important;
        color: #fff !important;
    }

    .NgxEditor__Popup {
        background-color: #191d25 !important;
    }

    .NgxEditor__MenuItem--Button,
    button {
        color: #043fcc;
        border: 1px solid #0c0c0d;
        background: transparent;
    }

    .NgxEditor__Placeholder:before {
        color: #aaabad !important;
    }

    .NgxEditor__Content {
        color: #aaabad !important;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #f7f8fa;
        }

        p {
            color: #d7d8db;
        }
    }
}
