@import "helpers/variable";
@import "helpers/dark-modal";
@import "helpers/theme";
@import "helpers/dark-menu";
@import "helpers/fonts";

.NeutralLight {
    color: #7B7D80;
}
.Neutral {
    color: #404042;
}
.dark-black {
    color: #0E0F0F;
}

.lh-10 {
    line-height: 10px;
}

.mt-pt-2{
    margin-top: 2.5rem;
}

.form-control::placeholder {
    color: #7B7D80;
}



.dark {
    .banner {

        input[type="text"],
        [type="number"],
        [type="href"] {
            background: transparent;
            border: 1px solid #3b3b3d;
            color: #2e6cff;
        }

        label,
        .menu-txt {
            color: #d7d8db !important;
        }
        

        ::placeholder {
            color: #aaabad;
        }

        .dark-table .form-check-input:checked[type="checkbox"] {
            background: url("../../assets/icons/checked.svg") no-repeat;
            background-color: #2e6cff;
            border-color: #2e6cff !important;
            background-position: center;

        }

        select {
            border: 1px solid #3b3b3d;
            color: #f7f8fa;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: #111112 url(../icons/dark-dropdown.svg) no-repeat;
            background-position: right 12px center;
        }

        .btn-light {
            background: #0c0c0d;
            border-color: #0c0c0d;
            color: #d7d8db;
        }

        .btn-light:hover {
            background: #191d25;
        }

        .active-btn {
            background-color: #ffffff !important;
            color: #111112 !important;
        }

        .dark-heading-outline {
            .btn-light-pink {
                background: #3e2133;
                color: #f7f8fa !important;
            }

            .btn-orange {
                background: #26231b;
                color: #f7f8fa !important;
            }
        }
        

        .dark-table tbody tr:hover td,
        .dark-table tbody tr:hover th 
        {
            background: #191d25 !important;
            color: #f7f8fa !important;
        }
        .dark-table tbody tr:hover td {
            .dark-chart {
                background: #191d25 !important;
            color: #f7f8fa !important;
            }
        }

        .dark-table {
                thead {
                    background-color: #111112 !important;
                }
        }

        .dark-table {
            .form-check-input[type="checkbox"] {
                border: 2px solid #aaabad;
            }

            .bi-bookmark {
                color: #aaabad;
                -webkit-text-stroke: 1px;
            }

            .bg-dark {
                background: #191d25;
                color: #f7f8fa;
            }

            .bg-light-blue {
                background: #191d25;
                color: #f7f8fa;
            }
        }
    }
}

.mat-select-placeholder {
    color: #7B7D80;
    font-weight: 500;
    font-size: 16px;
}

.table {

    td,
    th,
    tr {
        border-color: rgba(125, 126, 128, 0.15);
    }
    th{
        color: #7B7D80;

    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb {
    background: #d0d1d4;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #dedee0;
}


.custom-modal .modal-dialog {
    max-width: 1300px !important;
    justify-content: center;
}

@media (max-width: 1025px) {
    .custom-modal {
        .modal-dialog{
            max-width: 944px !important;
        }
    }
}







// for responsive scree 110%, 125%
@media only screen and (min-width: 991px) and (max-width: 1400px) {
    * {
        font-size: 12px;
    }

    .fs-16 , h3{
        font-size: 14px !important;
    }

    .fs-14 {
        font-size: 12px !important;
    }
    .fs-12 {
        font-size: 10px !important;
    }
    .fs-20 {
        font-size: 18px !important;
    }
    #myChart{
        height: 205px !important;
    width: 270px !important;
    }
    // .p-2 {
    //     padding: 0.275rem !important;
    // }
    // .m-2 {
    //     margin: 0.2rem !important;
    // }
    // .py-3 {
    //     padding-top: .5rem !important;
    //     padding-bottom: .5rem !important;
    // }
    // .pt-3{
    //     padding-top: .5rem !important;
    // }
    // .pb-3 {
    //     padding-bottom: .5rem !important;
    // }
    // .mt-4 {
    //     margin-top: .5rem !important;
    // }
    // .mb-4 {
    //     margin-bottom: .5rem !important;
    // }
    .form-control::placeholder{
        font-size: 12px;
    }

    table{
        td{
            font-size: 14px;
            
            span{
                font-size: 14px;
            }
        }
        .fs-12 {
            font-size: 12px !important;
        }
    }
 }
